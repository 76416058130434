<template>
 <div class="content-box">
        <div class="container">
    <el-breadcrumb separator="/">
      <el-breadcrumb-item>协同管理</el-breadcrumb-item>
      <el-breadcrumb-item>协同制造</el-breadcrumb-item>
      <el-breadcrumb-item>制造需求推送</el-breadcrumb-item>
    </el-breadcrumb>
    <div class="createButton">
      <el-button
         v-show="userType=='海装风电'||userType=='超级管理员'"
        style="margin-right: 12px"
        type="primary"
        @click="createNewDemand"
        >推送制造需求</el-button
      >
    </div>

    <div class="content_table">
      <el-table :data="tableData" border max-height="500">
        <el-table-column align="center" width="50" label="序号">
          <template slot-scope="scope">
            <span>{{ scope.$index + 1 }}</span>
          </template>
        </el-table-column>
        <el-table-column prop="manufacturingRelease.name" align="center" label="需求名称">
        </el-table-column>
        <el-table-column prop="manufacturingRelease.content" align="center" label="需求内容">
        </el-table-column>
        <el-table-column prop="manufacturingRelease.needCount" align="center" label="需求数量">
        </el-table-column>
         <!-- <el-table-column prop="userInfo.name" align="center" label="指定供应商">
        </el-table-column>
           <el-table-column prop="userInfo.company" align="center" label="供应商公司">
        </el-table-column> -->
        <el-table-column prop="manufacturingRelease.timeCreated" align="center" label="任务创建时间">
        </el-table-column>
        <el-table-column prop="manufacturingRelease.timeAbort" align="center" label="截止时间">
        </el-table-column>
        <el-table-column align="center" width="200" label="操作">
          <template slot-scope="scope">
            <!-- <el-button
              v-show="scope.row.state == 0"
              style="margin-right: 12px"
              @click="Send(scope.row, scope.$index)"
              type="success"
              size="mini"
              >推送</el-button
            >
           -->
            <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              confirm-button-type="danger"
              icon="el-icon-info"
              icon-color="red"
              title="确定删除吗？"
              v-show="userType=='超级管理员'||userType=='海装风电'"
              @confirm="deleteFile(scope.row, scope.$index)"
            >
              <el-button size="mini" type="danger" slot="reference"
                  style="margin-right:10px;"
                      v-show="scope.row.manufacturingRelease.stateMessage==0||2"
                >删除</el-button
              >
            </el-popconfirm>
           <el-popconfirm
               confirm-button-text="确定"
              cancel-button-text="取消"
              confirm-button-type="danger"
              icon="el-icon-info"
              icon-color="red"
              title="确定拒绝吗？"

               v-show="userType=='供应商'"
              @confirm="Reject(scope.row, scope.$index)"
            >
              <el-button size="mini" type="danger" slot="reference"
              v-show="scope.row.manufacturingRelease.stateMessage==0"
              style="margin-right:10px;"
                >拒绝</el-button
              >
            </el-popconfirm>
             <el-button size="mini" type="danger" slot="reference"
              v-show="scope.row.manufacturingRelease.stateMessage==2"
              disabled
           
                >已拒绝</el-button
              >

               <el-popconfirm
               v-show="userType=='供应商'"
              confirm-button-text="确定"
              cancel-button-text="取消"
              confirm-button-type="success"
              icon="el-icon-info"
              icon-color="red"
              title="确定接受吗？"
              @confirm="Accept(scope.row, scope.$index)"
            >
              <el-button size="mini" type="success" slot="reference"
              v-show="scope.row.manufacturingRelease.stateMessage==0"
                >接受</el-button
              >
            </el-popconfirm>
            <el-button size="mini" type="success" slot="reference"
              disabled
              v-show="scope.row.manufacturingRelease.stateMessage==1"
                >已接受</el-button
              >
          </template>
        </el-table-column>
      </el-table>
    </div>

    <el-dialog
      title="推送制造需求"
      :visible.sync="ceateDialogVisible"
      width="500px"
      :before-close="Cancel"
    >
      <el-form
        style="width: 400px"
        ref="manufacturFrom"
        :model="manufacturFrom"
        label-position="left"
        label-width="120px"
      >
        <el-form-item label="需求名称" required>
          <el-input
            v-model="manufacturFrom.name"
            placeholder="请输入需求名称"
            clearable
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label="需求内容" required>
          <el-input
            v-model="manufacturFrom.content"
            placeholder="请输入需求内容"
            clearable
            :style="{ width: '100%' }"
          ></el-input>
        </el-form-item>
        <el-form-item label="需求数量" required :style="{ width: '100%' }">
          <el-input-number
            :style="{ width: '100%' }"
            v-model="manufacturFrom.needCount"
            placeholder="需求数量"
            :step="10"
          ></el-input-number>
        </el-form-item>
        <el-form-item label="指定供应商" :style="{ width: '100%' }" required>
          <el-cascader
              v-model="manufacturFrom.test"
              :options="supplierOptions"
              :props="formProps"
              @change="supplierChange"
              clearable>
          </el-cascader>
        </el-form-item>
        <el-form-item label="截止时间" required>
          <el-date-picker
            v-model="manufacturFrom.timeAbort"
            format="yyyy-MM-dd HH:mm"
            value-format="yyyy-MM-dd HH:mm"
            :style="{ width: '100%' }"
            placeholder="请选择截止时间"
            clearable
          ></el-date-picker>
        </el-form-item>
        <el-form-item> </el-form-item>
      </el-form>
      <div slot="footer" class="footer">
        <el-button @click="Cancel">取 消</el-button>
        <el-button type="primary" @click="CreateData(manufacturFrom)"
          >确 定</el-button
        >
      </div>
    </el-dialog>
    <el_pagination
      :total="total"
      :num="num"
      @page-limit="page_limit"
    ></el_pagination>
   </div>
      </div>
</template>

<script>
import {
acceptOrders,
  deleteNeedList,
  pushCreate,
  needList,
  searcDemandPush,
  userReject,
    supplierList
} from "@/api/document";
import { getUserList } from "@/api/user";
export default {
  name: "DemandPush",
  components: {},
  data() {
    return {
      userType:localStorage.getItem('userType'),
      manufacturFrom: {},
      ceateDialogVisible: false,
      tableData: [],
      publishOptions: [],
      supplierOptions: [],
      mode: "add",
      data: {},
      pageSize: 10,
      currentPage: 1,
      total: 0,
      num: 0,
      otherRule: {
        userId: [
          { required: true, message: "至少选择一个供应商", trigger: "blur" },
        ],
      },
      statusOptions: [],
      formProps: {
        label: 'name',
        value: 'id',
        children: 'value'
      }
    };
  },
  created() {
    this.getFileList();
  },
  mounted() {
    this.fetchSupplierList();
  },
  methods: {
    // 分页子集传过来的数据  page = 页码  limit = 条数
    // 分页子集传过来的数据  page = 页码  limit = 条数
    page_limit(list) {
      console.log(list);
      let { page, limit } = list;
      this.currentPage = page;
      this.pageSize = limit;
      this.tableData = [];
      this.getFileList();
    },

    handleSizeChange(val) {
      console.log(`每页 ${val} 条`);
    },
    handleCurrentChange(val) {
      console.log(`当前页: ${val}`);
    },
    async getFileList() {
      let limit = this.pageSize;
      let page = this.currentPage;
      const res = await searcDemandPush({
        limit,
        page,
      });

      if (res.code === 200) {
        this.tableData = res.data;
        this.total = res.total;
        this.num = parseInt(this.total / limit + 1);
      }
    },
     //接受订单
    async Accept(row) {
      let id = row.manufacturingRelease.id;
      const res = await acceptOrders({
        id,
      });

      if (res.code === 200) {
        this.$message.success("你已接受此订单");
         await  this.getFileList()
      } else {
        this.$message.error("接单失败");
         await  this.getFileList()
      }
      await this.getFileList();
    },
    //拒绝订单
   async  Reject(row,$index){
    let  id  =  row.manufacturingRelease.id
    let   res  = await userReject({
      id,
    })

       if (res.code === 200) {
        this.$message.success("你已拒绝此订单");
         await  this.getFileList()
      } else {
        this.$message.error("拒单失败，请重试");
         await  this.getFileList()
      }

   },

    async fetchSupplierList(item) {
      const res = await supplierList()
      const data = res['data']
      data.forEach((item) => {
        item['name'] = item['company']
        item['id'] = item['key']
      })
      this.supplierOptions = data
    },

    //新增制造需求弹框
    createNewDemand() {
      this.ceateDialogVisible = true;
    },

    // 定向推送制造需求发送
   async  CreateData(manufacturFrom) {
      console.log(this.manufacturFrom);
      let res = await pushCreate(this.manufacturFrom);
      if (res.code == 200) {
        this.$message.success("推送成功，等待接单");
        this.ceateDialogVisible = false;
        this.manufacturFrom = {};
        this.getFileList()
      } else {
        this.$message.error("推送失败");
        this.ceateDialogVisible = false;
        this.manufacturFrom = {};
      }
    },
  //删除订单
     async deleteFile(row,$index){
      let  id   = row.manufacturingRelease.id
     let   res  = await  deleteNeedList({
    id
  })
  if (res.code === 200) {
        this.$message.success("你已删除此订单");
        await  this.getFileList()
      } else {
        this.$message.error("删除失败，请重试");
         await  this.getFileList()
      }
},

    //取消推送
    Cancel() {
      this.ceateDialogVisible = false;
      this.manufacturFrom = {};
    },
    supplierChange(res) {
      this.manufacturFrom['userId'] = res[res.length - 1]
    }
  },
};
</script>

<style lang="scss" scoped>
.button-gutter {
  margin: 20px 0;
}
.btn_item {
  margin-top: 50px;
  width: 100%;

  text-align: center;
}
.createButton {
  margin-top: 20px;
}
.content_table {
  margin-top: 20px;
}
::v-deep .el-dialog__header {
  text-align: center;
}
.footer {
  width: 300px;
  text-align: center;
  margin: 0 auto;
}
</style>

